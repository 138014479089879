@import "../../variables";

.InfoTooltip {
  display: flex;
  width: 20px;
  align-content: center;
  justify-content: center;
}

.MuiPopper-Tooltip {
  color: green;
}
