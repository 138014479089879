@import "../../variables";

.DashboardBox {
  font-size: 2.4rem;
  width: 100%;
  background: $secondaryColor;
  color: #ffffff;
  border-radius: $borderRadius;

  .DashboardBoxTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    h2 {
      color: #ffffff;
      margin: 0;
    }

    h3 {
      color: #ffffff;
      margin: 0;
    }

    .DashboardBoxIcon {
      svg {
        color: #ffffff;
      }
    }
  }

  .DashboardBoxChildren {
    p {
      color: $textColor;
      font-size: 16px;
    }

    .dataWrapper {
      .dataRow {
        display: flex;
        justify-content: space-between;

        p {
          margin: 0;
          color: #ffffff;
          font-weight: 100;
        }
      }
    }
  }
}

.flex-holder {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 10px;

  .flex-item {
    display: flex;
    flex-basis: 50%;

    @include md {
      flex-basis: 20%;

      a {
        margin-right: 15px;
      }
    }

    &:last-child a {
      margin-right: 0;
    }

    a {
      width: 100%;
      display: block;
    }
  }
}
