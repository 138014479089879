@import "../../variables";

.responsiveTable {
  width: 100%;
  border-spacing: 0;
  border-top: 2px solid $secondaryColor;
  font-size: 13px;

  thead {
    background-color: $lightGreenBackground;
    display: none;

    @include sm {
      display: table-header-group;
    }
  }

  .tableHead {
    cursor: pointer;
    font-family: $fontDefault;
    font-weight: 100;
  }

  &.sumRow {
    .tableRow:last-of-type {
      font-weight: bold;
      display: none;

      @include sm {
        display: table-row;
      }
    }
  }

  .tableRow.hiddenRow {
    background: $lightGreenBackground;
    padding: 5px;

    .entityValue {
      text-align: right;
    }

    .entityLabel {
      font-weight: bold;
    }

    .entityLabel,
    .entityValue {
      padding: 5px 10px;
    }
  }

  td {
    border-top: 1px solid $secondaryColor;
    border-bottom: 1px solid $secondaryColor;
    font-family: Arial, Helvetica, sans-serif;
  }

  tr + tr {
    td {
      border-top: 0 none;
    }
  }

  th,
  td {
    padding: 8px 16px;
    border-spacing: 0;
  }

  th {
    font-size: 13px;
    padding: 8px 16px 16px;
  }

  &.benefits {
    border-top: 0 none;

    .tableHead {
      cursor: pointer;
      background-color: transparent;
      color: $primaryColor;
    }

    .tableRow.hiddenRow {
      background: transparent;
      padding: 5px;
    }

    td {
      border-top: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
      font-family: Arial, Helvetica, sans-serif;
    }

    th {
      font-size: 1.2em;
    }

    th,
    td {
      padding: 8px 24px;
      border-spacing: 0;
    }

    th {
      padding: 8px 24px 16px;
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;

  .paginationControls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .previous,
    .next {
      padding: 5px 10px;
      margin: 0 5px;
      cursor: pointer;
    }
  }
}
