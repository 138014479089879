@import "../../variables";

.Tickets {
  .loginRequired {
    width: 80%;
    padding: 40px 30px;
    background: $secondaryColor;
    color: #ffffff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: $borderRadius;

    @include sm {
      width: 50%;
      padding: 2em;
    }
  }

  .loginOverlay {
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 10px;

    @include sm {
      top: 100px;
    }
  }
}
