@import '../../variables';

.ImageSlider {
  margin-bottom: 30px;
  background: transparent;
  border-radius: $borderRadius;

  @media (max-width: #{$breakpoint-md}) {
    background: $secondaryColor;
    padding-bottom: 20px !important;
  }

  .img-for-mobile {
    max-width: 100%;
    max-height: 400px;
  }

  .slide {
    width: 100%;
    padding-top: 30%;
    background-position: center center;
    background-size: cover;
    position: relative;
  }

  .swiper-pagination-bullet-active {
    background: $secondaryColor !important;
  }

  .swiper-pagination-bullet {
    background-color: #eee;
    opacity: 0.9;
    border: 1px solid #eee;
  }

  .content-box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
    background: $secondaryColor;
    padding: 24px;
    max-width: 40%;
    min-height: 262px;
    border-radius: $borderRadius;

    h4 {
      margin: 0;
      font-weight: 600;
      font-size: large;
      color: #baff79;
    }

    h2 {
      margin-top: 10px;
      color: white;
    }

    p {
      font-family: $fontMedium;
      font-size: 15px;
      letter-spacing: 0.5px;
      font-weight: 100;
      color: white;
    }

    .nicolaus-stoerer-class {
      position: absolute;
      width: 120px;
      height: 120px;
      top: -42%;
      right: 15%;
      border-radius: 100px;
      cursor: pointer;
      line-height: 18px;

      img {
        height: 200px;
      }

      & a {
        color: $borderColor;
        display: flex;
        border-radius: 100px;
        margin-top: 38px;
        margin-bottom: 28px;
      }

      .only-text {
        color: #f0f0f0;
        display: flex;
        border-radius: 100px;

        p {
          font-size: 16px;
          margin-top: 24px;
          margin-left: 8px;
        }
      }
    }

    .stoerer-class {
      background-color: #e3ffc9;
      position: absolute;
      width: 120px;
      height: 120px;
      top: -15%;
      left: 86%;
      border: 1px solid #e3ffc9;
      text-align: center;
      border-radius: 100px;
      transform: rotate(-15deg);
      cursor: pointer;
      line-height: 18px;

      img {
        height: 200px;
      }

      & a {
        color: $primaryColor;
        display: flex;
        font-weight: bold;
        font-size: 14px;
        border-radius: 100px;
        margin-top: 38px;
        margin-bottom: 28px;
      }

      .only-text {
        color: #f0f0f0;
        display: flex;
        border-radius: 100px;

        p {
          font-size: 16px;
          margin-top: 24px;
          margin-left: 8px;
        }
      }

      .highlighted {
        text-decoration: underline;
      }

      @media only screen and (min-width: 801px) and (max-width: 1000px) {
        top: 38%;
        left: 83%;
      }

      @media only screen and (min-width: 1001px) and (max-width:#{$breakpoint-md}) {
        top: 46%;
        left: 86%;
      }

      @media only screen and (min-width: 581px) and (max-width: 650px) {
        top: 26%;
        left: 76%;
      }

      @media only screen and (min-width: 651px) and (max-width: 800px) {
        top: 32%;
        left: 78%;
      }

      @media (max-width: 580px) {
        top: 22%;
        left: 64%;
      }
    }
  }

  @media (max-width: #{$breakpoint-md}) {
    .slide {
      padding-top: 100%;
    }

    .content-box {
      position: static;
      transform: none;
      bottom: 0;
      max-width: 100%;
      background: $secondaryColor;
    }
  }
}
