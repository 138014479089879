@import "../../variables";

.Home {
  @media (max-width: #{$breakpoint-md}) {
    .onlyMobile {
      .Headline {
        font-size: 40px;
        &.secondary {
          font-size: 25px;
        }
      }
    }
  }

  h2.Headline {
    font-weight: 100;
  }

  .twoColorLink,
  .twoColorLink:visited,
  .twoColorLink:focus,
  .twoColorLink:active,
  .twoColorLink:hover {
    color: $secondaryColor;
  }

  .crossLinkBox {
    margin-bottom: 70px;

    @include sm {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }

  .cashbackSpecials {
    margin-bottom: 30px;

    @include sm {
      margin-bottom: 0;
    }
  }
}
