@import "./variables";

@font-face {
  font-family: "galano-neue";
  src: url(/assets/fonts/RBGalanoNeue-Bold.woff2);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "galano-neue-medium";
  src: url(/assets/fonts/RBGalanoNeue-Medium.woff2);
  font-weight: 300;
  font-style: normal;
}

html {
  font-size: 82%;

  // @include xs {
  //   font-size: 50%;
  // }
  // @include sm {
  //   font-size: 50%;
  // }
  // @include md {
  //   font-size: 87.5%;
  // }
  // @include lg {
  //   font-size: 87.5%;
  // }
  // @include xl {
  //   font-size: 87.5%;
  // }
}

body {
  font-size: $fontDefault;
  line-height: 2rem;
  color: $textColor;
  font-family: "Arial", sans-serif;
}

.MuiButton-root .MuiButton-label {
  font-size: 16px !important;
  letter-spacing: 0.6px;

  @include xs {
    font-size: 18px !important;
  }
}

.MuiButton-root.tabButton .MuiButton-label {
  font-weight: 600 !important;
}

.MuiButton-root.MuiButton-outlined {
  padding: 8px 20px !important;
}

h1,
h2,
h3,
h4 {
  font-weight: 300;
  letter-spacing: 0;
  font-family: $fontDefault;
  // color: $secondaryColor;

  span {
    font-family: $fontDefault;
  }
}

h1 {
  line-height: 1.1;
  font-weight: 600;
}

h2 {
  line-height: 1.5;
  font-weight: 600;
  margin: 0.5em 0;
}

h3 {
  font-weight: 600;
}

h4 {
  font-weight: 600;
}

[class*="Tile"] {
  h2 {
    font-weight: 300;
    margin: 0;
  }
}

.font-extra-small {
  font-size: $fontSizeExtraSmall;
}

.font-small {
  font-size: $fontSizeSmall;
}

a.moreLink {
  font-size: 18px;
  font-family: $fontDefault;
}

.Box {
  ol {
    list-style: none;
    counter-reset: list-counter;

    li {
      margin-bottom: 10px;
    }

    li::before {
      content: counter(list-counter);
      counter-increment: list-counter;
      display: inline-block;
      background: #002d1d;
      padding: 0;
      font-family: "galano-neue", sans-serif;
      color: #ffffff;
      width: 25px;
      margin-left: -33px;
      margin-right: 8px;
      text-align: center;
      direction: rtl;
      border-radius: 3px;
      height: 25px;
      line-height: 25px;
    }
  }
}
