@import "../../variables";

.InputWithButton {
  width: 100%;

  &.MuiPaper-root {
    display: flex;
    justify-content: space-between;
    border: 1px solid #cccccc;
    background: #fff;
    border-radius: $borderRadius;

    .MuiInputBase-root {
      padding: 0 15px;
      height: auto;
      line-height: 44px;
      flex: 1;
      color: #707070;
      font-weight: 300;

      & input::placeholder {
        opacity: 1;
      }
    }
  }
}
