$breakpoint-xs: "0px";
$breakpoint-sm: "600px";
$breakpoint-md: "1140px";
$breakpoint-lg: "1280px";
$breakpoint-xl: "1920px";

@mixin xs {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

$primaryColor: #002D1D;
$secondaryColor: #005f3d;
$textColor: $primaryColor;
$lightTextColor: #737373;
$highlightColor: #009fd6;
$borderColor: #f0f0f0;
$lightGreenBackground: #e3ffc9;

$errorColorLight: #f4b0a9;
$errorColorLighter: #fdf1f0;
$errorColorDarker: #c15b52;
$warningColorLight: #faec91;
$warningColorLighter: #fdf8da;
$warningColorDarker: #c4af2b;
$successColorLighter: #b9ddb4;
$successColorLight: #91cb8a;
$successColorDarker: #77a771;

$starColor: #fcc200;

$fontDefault: "galano-neue", sans-serif;
$fontMedium: "galano-neue-medium", sans-serif;
$fontSpecial: "galano-neue", sans-serif;

$fontSizeDefault: 1.15rem;
$fontSizeSmall: 0.857rem;
$fontSizeExtraSmall: 0.714rem;

$fontSizeHeadline1: 2.9rem; // 40px
$fontSizeHeadline2: 1.8rem; // 25px
$fontSizeHeadline3: 1.15rem; // 16px
$fontSizeHeadline4: 0.86rem; // 12px

$boxPadding: 30px 30px;

$borderRadius: 40px;
