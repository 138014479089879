@import "../../variables";

.navigation {
  z-index: 20;
  background: #fff;
  border-top: 20px solid $secondaryColor;
  box-shadow: 0 0 10px #00000044;
  text-transform: uppercase;
  position: relative;
  font-family: $fontDefault;

  .nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25rem;
    padding: 0 18px;

    .nav-item-list {
      flex-grow: 1;
      padding: 0 0 0 50px;

      nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
          color: $textColor;
          font-weight: 600;

          &.active {
            border-bottom: 2px solid $secondaryColor;
            margin-bottom: -2px;
            color: $secondaryColor;
          }
        }
      }
    }
  }

  .login {
    svg {
      margin-right: 6px;
    }
  }

  .logo {
    padding-left: 0;

    @include md {
      padding-left: 10px;
    }

    a {
      color: $textColor;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.1;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.1px;
      margin: 25px 0;

      @include md {
        margin: 25px 0;
      }

      span {
        display: none;

        @include md {
          display: inline;
        }
      }

      img {
        width: 96px;
        height: 48px;
        margin-left: 0;
      }
    }
  }

  .profile {
    &:hover {
      opacity: 0.9;
    }

    button {
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      margin-right: 1px;
      height: 40px;
      padding: 8px 10px;

      @include xs {
        padding: 8px 20px;
      }
    }
  }

  .logout {
    &:hover {
      opacity: 0.9;
    }

    button {
      height: 40px;
      border-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      min-width: 0;
      padding: 8px 10px;

      @include xs {
        padding: 8px 20px;
      }
    }
  }

  @include lg {
    .burger {
      display: none;
    }
  }

  @media (max-width: #{$breakpoint-lg}) {
    & > .logo {
      align-self: flex-start;
      flex: 1;
    }
  }
}
