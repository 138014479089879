@import "../../variables";

.shortlinkWrapper {
  height: auto;
  flex: 1;
  width: 100%;
  flex-basis: 100%;

  @include md {
    flex-basis: 0;
    height: 100%;
    width: auto;
  }
}

.ShortlinkBox {
  text-align: center;
  padding: 40px 15px 80px 15px;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include md {
    height: 100%;
  }

  h3 {
    color: $textColor;
    margin: 15px 0 0;
  }

  .ShortlinkBoxIcon {
    margin-bottom: 20px;
    font-size: 2.4rem;

    svg {
      color: $secondaryColor;
    }
  }

  .ShortlinkBoxTitle {
    height: auto;
    display: flex;
    align-items: center;

    @include sm {
      height: 80px;
    }
  }

  .ShortlinkBoxChildren {
    margin: auto 0;
    flex-grow: 1;

    p {
      color: $textColor;
      font-size: 15px;
      margin: 0;
      margin-bottom: 20px;
    }
  }

  button {
    width: auto;
    flex-grow: 0;
  }
}

.flex-holder {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 10px;
  justify-content: space-between;

  .flex-item {
    display: flex;
    flex-basis: 50%;
    min-width: 50%;

    @include md {
      flex-basis: 25%;
      min-width: 25%;
    }

    a {
      width: 100%;
      display: block;
    }
  }
}
