@import "../../variables";

.Box {
  padding: $boxPadding;
  background-color: #fff;
  border-radius: $borderRadius;

  &.colorful {
    background-color: #e3ffc9;
  }
}
